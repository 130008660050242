import styled from 'styled-components';

const HippaIconsStyle = styled.div`
    .commone_icons_text {
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        z-index: 1;
        p{
            color: #000;
        }
    }
    .subscribe-new-ltr{
        display: flex;
        justify-content: center;
        background: #fff;
        padding: 20px;
        @media (max-width: 767px){
            flex-wrap: wrap;
        }
        @media (max-width:767px){
        .latest-signup{
            text-align: center;
        }
    }
    .news-ltr-sign {
        width: 100%;
        max-width: 280px;
        margin-top: 10px;
        padding-left: 14px;
        @media (max-width: 767px){
            max-width: 300px;
        }
    }
    .subscribe-signup {
        margin-top: 10px;
        @media (max-width: 767px){
            max-width: 100%;
        }
    }
        input.signup-email {
            width: 100%;
            height: 34px;
            border-radius: 15px;
            border: 2px solid #cad3d7e0;
            text-align: center;
            padding-inline: 15px;
    }
    button.subs-news-ltr {
        cursor: pointer;
        background:linear-gradient(90deg, #20a4dd 0%, #1ea3b6 24%, #25a07b 100%);
        color: #fff;
        border-radius: 20px;
        width: 100%;
       
       }
}
`;

export default HippaIconsStyle;